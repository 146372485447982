import { render, staticRenderFns } from "./parents.vue?vue&type=template&id=58e5d074&scoped=true"
import script from "./parents.vue?vue&type=script&lang=js"
export * from "./parents.vue?vue&type=script&lang=js"
import style0 from "./parents.vue?vue&type=style&index=0&id=58e5d074&prod&scoped=true&lang=scss"
import style1 from "./parents.vue?vue&type=style&index=1&id=58e5d074&prod&scoped=true&lang=css"
import style2 from "./parents.vue?vue&type=style&index=2&id=58e5d074&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e5d074",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CountryFlag: require('/codebuild/output/src687347117/src/client/components/CountryFlag.vue').default,HomeFooter: require('/codebuild/output/src687347117/src/client/components/home/HomeFooter.vue').default,ProgressBar: require('/codebuild/output/src687347117/src/client/components/ProgressBar.vue').default,PopupMessage: require('/codebuild/output/src687347117/src/client/components/popup-dialog/PopupMessage.vue').default,Snackbar: require('/codebuild/output/src687347117/src/client/components/Snackbar.vue').default})
