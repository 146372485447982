import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _255c4cb5 = () => interopDefault(import('../pages/aboutUs.vue' /* webpackChunkName: "pages/aboutUs" */))
const _4f9281c8 = () => interopDefault(import('../pages/aboutUsPaytm.vue' /* webpackChunkName: "pages/aboutUsPaytm" */))
const _b4ee528c = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _88ae32ce = () => interopDefault(import('../pages/ccsupport.vue' /* webpackChunkName: "pages/ccsupport" */))
const _20e51d42 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _ce15723a = () => interopDefault(import('../pages/claim-your-gift.vue' /* webpackChunkName: "pages/claim-your-gift" */))
const _26e7843b = () => interopDefault(import('../pages/class-2-worksheets.vue' /* webpackChunkName: "pages/class-2-worksheets" */))
const _1f2c6ab1 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _6c142184 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _f10b705e = () => interopDefault(import('../pages/email-template.vue' /* webpackChunkName: "pages/email-template" */))
const _08ae75fb = () => interopDefault(import('../pages/english-worksheet.vue' /* webpackChunkName: "pages/english-worksheet" */))
const _f9011780 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _5c41ed84 = () => interopDefault(import('../pages/forgotpassword.vue' /* webpackChunkName: "pages/forgotpassword" */))
const _685e3143 = () => interopDefault(import('../pages/free-signup.vue' /* webpackChunkName: "pages/free-signup" */))
const _04ff2a13 = () => interopDefault(import('../pages/giftsubscription.vue' /* webpackChunkName: "pages/giftsubscription" */))
const _1da685c1 = () => interopDefault(import('../pages/history/index.vue' /* webpackChunkName: "pages/history/index" */))
const _1c7d5a93 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _05f724a2 = () => interopDefault(import('../pages/math-worksheet.vue' /* webpackChunkName: "pages/math-worksheet" */))
const _64f7301b = () => interopDefault(import('../pages/myplan.vue' /* webpackChunkName: "pages/myplan" */))
const _30dbeb09 = () => interopDefault(import('../pages/payNearBySignup.vue' /* webpackChunkName: "pages/payNearBySignup" */))
const _5a847564 = () => interopDefault(import('../pages/payNearBySignUpMessage.vue' /* webpackChunkName: "pages/payNearBySignUpMessage" */))
const _53700fc8 = () => interopDefault(import('../pages/paytmlanding.vue' /* webpackChunkName: "pages/paytmlanding" */))
const _7ece7ae2 = () => interopDefault(import('../pages/paytmsubscribe.vue' /* webpackChunkName: "pages/paytmsubscribe" */))
const _ede66b48 = () => interopDefault(import('../pages/press-releases.vue' /* webpackChunkName: "pages/press-releases" */))
const _094e2b64 = () => interopDefault(import('../pages/privacypolicy.vue' /* webpackChunkName: "pages/privacypolicy" */))
const _ed7e31ce = () => interopDefault(import('../pages/profileImageUpload.vue' /* webpackChunkName: "pages/profileImageUpload" */))
const _00a0dd3a = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _545eacd8 = () => interopDefault(import('../pages/resetpassword.vue' /* webpackChunkName: "pages/resetpassword" */))
const _2fc63ab0 = () => interopDefault(import('../pages/returnpolicy.vue' /* webpackChunkName: "pages/returnpolicy" */))
const _01fdccbf = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _31a089f5 = () => interopDefault(import('../pages/signon-new.vue' /* webpackChunkName: "pages/signon-new" */))
const _13e20eb1 = () => interopDefault(import('../pages/signup-new.vue' /* webpackChunkName: "pages/signup-new" */))
const _3b178959 = () => interopDefault(import('../pages/signup-partner.vue' /* webpackChunkName: "pages/signup-partner" */))
const _39eec541 = () => interopDefault(import('../pages/signup-with-samples.vue' /* webpackChunkName: "pages/signup-with-samples" */))
const _6f955133 = () => interopDefault(import('../pages/ssr-test.vue' /* webpackChunkName: "pages/ssr-test" */))
const _d2eb16a2 = () => interopDefault(import('../pages/staugustinesday.vue' /* webpackChunkName: "pages/staugustinesday" */))
const _01a8e43e = () => interopDefault(import('../pages/students/index.vue' /* webpackChunkName: "pages/students/index" */))
const _515696f4 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _7c697b55 = () => interopDefault(import('../pages/termsandcondition.vue' /* webpackChunkName: "pages/termsandcondition" */))
const _757bded8 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _5e72ccfc = () => interopDefault(import('../pages/token-login/index.vue' /* webpackChunkName: "pages/token-login/index" */))
const _bce0e8ba = () => interopDefault(import('../pages/trial-ads.vue' /* webpackChunkName: "pages/trial-ads" */))
const _c8645d7e = () => interopDefault(import('../pages/try-sample-question.vue' /* webpackChunkName: "pages/try-sample-question" */))
const _4c3f0148 = () => interopDefault(import('../pages/under-maintenance.vue' /* webpackChunkName: "pages/under-maintenance" */))
const _2ee7018a = () => interopDefault(import('../pages/varify-email.vue' /* webpackChunkName: "pages/varify-email" */))
const _0963de44 = () => interopDefault(import('../pages/admin/active-child/index.vue' /* webpackChunkName: "pages/admin/active-child/index" */))
const _6a7aa576 = () => interopDefault(import('../pages/admin/additional-points/index.vue' /* webpackChunkName: "pages/admin/additional-points/index" */))
const _008dd252 = () => interopDefault(import('../pages/admin/archive-points/index.vue' /* webpackChunkName: "pages/admin/archive-points/index" */))
const _17ef3f90 = () => interopDefault(import('../pages/admin/child-accuracy.vue' /* webpackChunkName: "pages/admin/child-accuracy" */))
const _188bc224 = () => interopDefault(import('../pages/admin/child-active-history.vue' /* webpackChunkName: "pages/admin/child-active-history" */))
const _b4f0b8a0 = () => interopDefault(import('../pages/admin/child-competition-details/index.vue' /* webpackChunkName: "pages/admin/child-competition-details/index" */))
const _fb6cf60c = () => interopDefault(import('../pages/admin/child-monthly-points.vue' /* webpackChunkName: "pages/admin/child-monthly-points" */))
const _86fc86fe = () => interopDefault(import('../pages/admin/child-points-history.vue' /* webpackChunkName: "pages/admin/child-points-history" */))
const _876d66da = () => interopDefault(import('../pages/admin/competition-winner.vue' /* webpackChunkName: "pages/admin/competition-winner" */))
const _403f26ba = () => interopDefault(import('../pages/admin/coupons/index.vue' /* webpackChunkName: "pages/admin/coupons/index" */))
const _4c465b01 = () => interopDefault(import('../pages/admin/create-question.vue' /* webpackChunkName: "pages/admin/create-question" */))
const _ca90c5d4 = () => interopDefault(import('../pages/admin/create-question-set.vue' /* webpackChunkName: "pages/admin/create-question-set" */))
const _13734346 = () => interopDefault(import('../pages/admin/custom-ads/index.vue' /* webpackChunkName: "pages/admin/custom-ads/index" */))
const _51504a83 = () => interopDefault(import('../pages/admin/daily-question-performance.vue' /* webpackChunkName: "pages/admin/daily-question-performance" */))
const _1f072133 = () => interopDefault(import('../pages/admin/edit-question.vue' /* webpackChunkName: "pages/admin/edit-question" */))
const _794c69ee = () => interopDefault(import('../pages/admin/encryption.vue' /* webpackChunkName: "pages/admin/encryption" */))
const _5196dee7 = () => interopDefault(import('../pages/admin/leaderboard.vue' /* webpackChunkName: "pages/admin/leaderboard" */))
const _1ea8db88 = () => interopDefault(import('../pages/admin/leaderboard-referal/index.vue' /* webpackChunkName: "pages/admin/leaderboard-referal/index" */))
const _3867d268 = () => interopDefault(import('../pages/admin/lessons/index.vue' /* webpackChunkName: "pages/admin/lessons/index" */))
const _60cddb12 = () => interopDefault(import('../pages/admin/question-performance/index.vue' /* webpackChunkName: "pages/admin/question-performance/index" */))
const _94bc0d70 = () => interopDefault(import('../pages/admin/question-set-history.vue' /* webpackChunkName: "pages/admin/question-set-history" */))
const _0b8b2e46 = () => interopDefault(import('../pages/admin/question-set-of-the-day.vue' /* webpackChunkName: "pages/admin/question-set-of-the-day" */))
const _5869fd37 = () => interopDefault(import('../pages/admin/questions.vue' /* webpackChunkName: "pages/admin/questions" */))
const _33c5eb5d = () => interopDefault(import('../pages/admin/questionSets.vue' /* webpackChunkName: "pages/admin/questionSets" */))
const _5506cc00 = () => interopDefault(import('../pages/admin/shop-order/index.vue' /* webpackChunkName: "pages/admin/shop-order/index" */))
const _7efc7a80 = () => interopDefault(import('../pages/admin/stories/index.vue' /* webpackChunkName: "pages/admin/stories/index" */))
const _984f603a = () => interopDefault(import('../pages/admin/subscriptions/index.vue' /* webpackChunkName: "pages/admin/subscriptions/index" */))
const _1d1892d8 = () => interopDefault(import('../pages/admin/undermaintance.vue' /* webpackChunkName: "pages/admin/undermaintance" */))
const _5f165675 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _5ae4d0e0 = () => interopDefault(import('../pages/admin/wild-card/index.vue' /* webpackChunkName: "pages/admin/wild-card/index" */))
const _2b39845a = () => interopDefault(import('../pages/advertisers/dashboard/index.vue' /* webpackChunkName: "pages/advertisers/dashboard/index" */))
const _61b42bda = () => interopDefault(import('../pages/affiliate/sign-up.vue' /* webpackChunkName: "pages/affiliate/sign-up" */))
const _ded2a888 = () => interopDefault(import('../pages/affiliate/terms-and-conditions.vue' /* webpackChunkName: "pages/affiliate/terms-and-conditions" */))
const _0cf1a336 = () => interopDefault(import('../pages/coupon-users/coupons.vue' /* webpackChunkName: "pages/coupon-users/coupons" */))
const _157e56e1 = () => interopDefault(import('../pages/history/HistoryList.vue' /* webpackChunkName: "pages/history/HistoryList" */))
const _5ee884b0 = () => interopDefault(import('../pages/parents/achievements.vue' /* webpackChunkName: "pages/parents/achievements" */))
const _f6a3db64 = () => interopDefault(import('../pages/parents/assessment.vue' /* webpackChunkName: "pages/parents/assessment" */))
const _57633e7f = () => interopDefault(import('../pages/parents/challenges/index.vue' /* webpackChunkName: "pages/parents/challenges/index" */))
const _572b756e = () => interopDefault(import('../pages/parents/chat-history/index.vue' /* webpackChunkName: "pages/parents/chat-history/index" */))
const _31b7c496 = () => interopDefault(import('../pages/parents/chatTest.vue' /* webpackChunkName: "pages/parents/chatTest" */))
const _5a04ef9f = () => interopDefault(import('../pages/parents/child-profile/index.vue' /* webpackChunkName: "pages/parents/child-profile/index" */))
const _6baf70e0 = () => interopDefault(import('../pages/parents/claimgift.vue' /* webpackChunkName: "pages/parents/claimgift" */))
const _7b4841d2 = () => interopDefault(import('../pages/parents/create-child.vue' /* webpackChunkName: "pages/parents/create-child" */))
const _ed0d1986 = () => interopDefault(import('../pages/parents/daily-questions.vue' /* webpackChunkName: "pages/parents/daily-questions" */))
const _ca815cda = () => interopDefault(import('../pages/parents/digital-store/index.vue' /* webpackChunkName: "pages/parents/digital-store/index" */))
const _092b5034 = () => interopDefault(import('../pages/parents/friend-request-approval.vue' /* webpackChunkName: "pages/parents/friend-request-approval" */))
const _7c57a37c = () => interopDefault(import('../pages/parents/jump-request.vue' /* webpackChunkName: "pages/parents/jump-request" */))
const _94bf4f16 = () => interopDefault(import('../pages/parents/jump-test.vue' /* webpackChunkName: "pages/parents/jump-test" */))
const _5516610e = () => interopDefault(import('../pages/parents/kids.vue' /* webpackChunkName: "pages/parents/kids" */))
const _a5740fbe = () => interopDefault(import('../pages/parents/leaderboard.vue' /* webpackChunkName: "pages/parents/leaderboard" */))
const _cb43f7b6 = () => interopDefault(import('../pages/parents/mykids.vue' /* webpackChunkName: "pages/parents/mykids" */))
const _5b5ceb12 = () => interopDefault(import('../pages/parents/parent-profile.vue' /* webpackChunkName: "pages/parents/parent-profile" */))
const _10459a8a = () => interopDefault(import('../pages/parents/practice/index.vue' /* webpackChunkName: "pages/parents/practice/index" */))
const _2ce80e26 = () => interopDefault(import('../pages/parents/pricing/index.vue' /* webpackChunkName: "pages/parents/pricing/index" */))
const _8719f188 = () => interopDefault(import('../pages/parents/social/index.vue' /* webpackChunkName: "pages/parents/social/index" */))
const _a96ba58c = () => interopDefault(import('../pages/parents/stories/index.vue' /* webpackChunkName: "pages/parents/stories/index" */))
const _7b383bae = () => interopDefault(import('../pages/parents/subscription.vue' /* webpackChunkName: "pages/parents/subscription" */))
const _7b2581c4 = () => interopDefault(import('../pages/parents/test.vue' /* webpackChunkName: "pages/parents/test" */))
const _18769240 = () => interopDefault(import('../pages/parents/thank-you.vue' /* webpackChunkName: "pages/parents/thank-you" */))
const _32b7eba0 = () => interopDefault(import('../pages/shop-users/orders/index.vue' /* webpackChunkName: "pages/shop-users/orders/index" */))
const _1d5c446c = () => interopDefault(import('../pages/shop-users/products.vue' /* webpackChunkName: "pages/shop-users/products" */))
const _0ca78c1c = () => interopDefault(import('../pages/shop/brands/index.vue' /* webpackChunkName: "pages/shop/brands/index" */))
const _44c6e262 = () => interopDefault(import('../pages/shop/Brands.vue' /* webpackChunkName: "pages/shop/Brands" */))
const _c9b505f4 = () => interopDefault(import('../pages/shop/cart/index.vue' /* webpackChunkName: "pages/shop/cart/index" */))
const _315138c2 = () => interopDefault(import('../pages/shop/categories/index.vue' /* webpackChunkName: "pages/shop/categories/index" */))
const _46d1d99f = () => interopDefault(import('../pages/shop/Categories.vue' /* webpackChunkName: "pages/shop/Categories" */))
const _24c1fa2c = () => interopDefault(import('../pages/shop/checkout/index.vue' /* webpackChunkName: "pages/shop/checkout/index" */))
const _5a27f2d4 = () => interopDefault(import('../pages/shop/Deals.vue' /* webpackChunkName: "pages/shop/Deals" */))
const _ab877806 = () => interopDefault(import('../pages/shop/offers/index.vue' /* webpackChunkName: "pages/shop/offers/index" */))
const _d55885cc = () => interopDefault(import('../pages/shop/Offers.vue' /* webpackChunkName: "pages/shop/Offers" */))
const _e20e38f6 = () => interopDefault(import('../pages/shop/order-history/index.vue' /* webpackChunkName: "pages/shop/order-history/index" */))
const _784a70ca = () => interopDefault(import('../pages/shop/products/index.vue' /* webpackChunkName: "pages/shop/products/index" */))
const _0c8e5408 = () => interopDefault(import('../pages/admin/additional-points/create.vue' /* webpackChunkName: "pages/admin/additional-points/create" */))
const _3ffc75b2 = () => interopDefault(import('../pages/admin/advertisements/ad-specification/index.vue' /* webpackChunkName: "pages/admin/advertisements/ad-specification/index" */))
const _7782a520 = () => interopDefault(import('../pages/admin/advertisements/advertisers/index.vue' /* webpackChunkName: "pages/admin/advertisements/advertisers/index" */))
const _1402f9a4 = () => interopDefault(import('../pages/admin/advertisements/formats/index.vue' /* webpackChunkName: "pages/admin/advertisements/formats/index" */))
const _0ed59592 = () => interopDefault(import('../pages/admin/advertisements/units/index.vue' /* webpackChunkName: "pages/admin/advertisements/units/index" */))
const _496d408d = () => interopDefault(import('../pages/admin/coupons/affiliate-inquiries.vue' /* webpackChunkName: "pages/admin/coupons/affiliate-inquiries" */))
const _8a3986fc = () => interopDefault(import('../pages/admin/coupons/coupon-usage.vue' /* webpackChunkName: "pages/admin/coupons/coupon-usage" */))
const _26399c98 = () => interopDefault(import('../pages/admin/digital-store/trades.vue' /* webpackChunkName: "pages/admin/digital-store/trades" */))
const _cef34c84 = () => interopDefault(import('../pages/admin/shop/masters.vue' /* webpackChunkName: "pages/admin/shop/masters" */))
const _f26b8086 = () => interopDefault(import('../pages/admin/shop/offers/index.vue' /* webpackChunkName: "pages/admin/shop/offers/index" */))
const _0d50b0ea = () => interopDefault(import('../pages/admin/shop/orders/index.vue' /* webpackChunkName: "pages/admin/shop/orders/index" */))
const _69487c8a = () => interopDefault(import('../pages/admin/shop/products/index.vue' /* webpackChunkName: "pages/admin/shop/products/index" */))
const _2234118a = () => interopDefault(import('../pages/admin/subscriptions/create.vue' /* webpackChunkName: "pages/admin/subscriptions/create" */))
const _2b3bcc45 = () => interopDefault(import('../pages/admin/subscriptions/track-subscriptions.vue' /* webpackChunkName: "pages/admin/subscriptions/track-subscriptions" */))
const _3e00dd08 = () => interopDefault(import('../pages/parents/challenges/trending.vue' /* webpackChunkName: "pages/parents/challenges/trending" */))
const _10efc578 = () => interopDefault(import('../pages/parents/challenges/winners/index.vue' /* webpackChunkName: "pages/parents/challenges/winners/index" */))
const _20690e8a = () => interopDefault(import('../pages/parents/digital-store/avatars.vue' /* webpackChunkName: "pages/parents/digital-store/avatars" */))
const _722b4dee = () => interopDefault(import('../pages/parents/digital-store/cars.vue' /* webpackChunkName: "pages/parents/digital-store/cars" */))
const _404adfce = () => interopDefault(import('../pages/parents/digital-store/collections/index.vue' /* webpackChunkName: "pages/parents/digital-store/collections/index" */))
const _790c17db = () => interopDefault(import('../pages/parents/digital-store/icons.vue' /* webpackChunkName: "pages/parents/digital-store/icons" */))
const _233be540 = () => interopDefault(import('../pages/parents/digital-store/inventory/index.vue' /* webpackChunkName: "pages/parents/digital-store/inventory/index" */))
const _62639e55 = () => interopDefault(import('../pages/parents/digital-store/limited-deals.vue' /* webpackChunkName: "pages/parents/digital-store/limited-deals" */))
const _a495b6be = () => interopDefault(import('../pages/parents/digital-store/mystry-box.vue' /* webpackChunkName: "pages/parents/digital-store/mystry-box" */))
const _232530f6 = () => interopDefault(import('../pages/parents/digital-store/new-arrivals.vue' /* webpackChunkName: "pages/parents/digital-store/new-arrivals" */))
const _7d385e18 = () => interopDefault(import('../pages/parents/digital-store/orders.vue' /* webpackChunkName: "pages/parents/digital-store/orders" */))
const _e0c598fa = () => interopDefault(import('../pages/parents/digital-store/pets.vue' /* webpackChunkName: "pages/parents/digital-store/pets" */))
const _06b44cb2 = () => interopDefault(import('../pages/parents/digital-store/trade-offers/index.vue' /* webpackChunkName: "pages/parents/digital-store/trade-offers/index" */))
const _6acab155 = () => interopDefault(import('../pages/parents/practice/opponent.vue' /* webpackChunkName: "pages/parents/practice/opponent" */))
const _21a4933a = () => interopDefault(import('../pages/parents/practice/subjectSelector.vue' /* webpackChunkName: "pages/parents/practice/subjectSelector" */))
const _622ac1ca = () => interopDefault(import('../pages/parents/stories/storyList.vue' /* webpackChunkName: "pages/parents/stories/storyList" */))
const _2aca7104 = () => interopDefault(import('../pages/shop/order-history/track.vue' /* webpackChunkName: "pages/shop/order-history/track" */))
const _3af5ed17 = () => interopDefault(import('../pages/parents/digital-store/inventory/Profile.vue' /* webpackChunkName: "pages/parents/digital-store/inventory/Profile" */))
const _52d5b77c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _179f4c62 = () => interopDefault(import('../pages/parents/digital-store/trade-offers/history/_id/index.vue' /* webpackChunkName: "pages/parents/digital-store/trade-offers/history/_id/index" */))
const _0d794bd8 = () => interopDefault(import('../pages/admin/advertisements/advertisers/_id.vue' /* webpackChunkName: "pages/admin/advertisements/advertisers/_id" */))
const _778d195f = () => interopDefault(import('../pages/admin/advertisements/units/_id.vue' /* webpackChunkName: "pages/admin/advertisements/units/_id" */))
const _0ad33428 = () => interopDefault(import('../pages/admin/shop/offers/_id/index.vue' /* webpackChunkName: "pages/admin/shop/offers/_id/index" */))
const _1e1624bc = () => interopDefault(import('../pages/admin/shop/orders/_item_id/index.vue' /* webpackChunkName: "pages/admin/shop/orders/_item_id/index" */))
const _51c77b16 = () => interopDefault(import('../pages/admin/shop/products/_id/index.vue' /* webpackChunkName: "pages/admin/shop/products/_id/index" */))
const _4644a0ac = () => interopDefault(import('../pages/parents/challenges/winners/_id.vue' /* webpackChunkName: "pages/parents/challenges/winners/_id" */))
const _f8fe25dc = () => interopDefault(import('../pages/parents/child-profile/certificate/_id/index.vue' /* webpackChunkName: "pages/parents/child-profile/certificate/_id/index" */))
const _6f6812f8 = () => interopDefault(import('../pages/parents/digital-store/collections/_id/index.vue' /* webpackChunkName: "pages/parents/digital-store/collections/_id/index" */))
const _3c53be9d = () => interopDefault(import('../pages/parents/digital-store/trade-offers/_id/index.vue' /* webpackChunkName: "pages/parents/digital-store/trade-offers/_id/index" */))
const _2ff81561 = () => interopDefault(import('../pages/admin/additional-points/_id/index.vue' /* webpackChunkName: "pages/admin/additional-points/_id/index" */))
const _bd5b56b6 = () => interopDefault(import('../pages/admin/coupons/_id/index.vue' /* webpackChunkName: "pages/admin/coupons/_id/index" */))
const _b4bb57f6 = () => interopDefault(import('../pages/admin/custom-ads/_id.vue' /* webpackChunkName: "pages/admin/custom-ads/_id" */))
const _1dd16260 = () => interopDefault(import('../pages/admin/lessons/_id.vue' /* webpackChunkName: "pages/admin/lessons/_id" */))
const _53d7552a = () => interopDefault(import('../pages/admin/stories/_id/index.vue' /* webpackChunkName: "pages/admin/stories/_id/index" */))
const _1d452ee0 = () => interopDefault(import('../pages/admin/users/_id/index.vue' /* webpackChunkName: "pages/admin/users/_id/index" */))
const _3f2a7bcb = () => interopDefault(import('../pages/admin/wild-card/_id/index.vue' /* webpackChunkName: "pages/admin/wild-card/_id/index" */))
const _e94656b2 = () => interopDefault(import('../pages/parents/challenges/_id.vue' /* webpackChunkName: "pages/parents/challenges/_id" */))
const _3447d2d0 = () => interopDefault(import('../pages/parents/edit-child/_id.vue' /* webpackChunkName: "pages/parents/edit-child/_id" */))
const _5a6dcf8c = () => interopDefault(import('../pages/parents/payment-detials/_id.vue' /* webpackChunkName: "pages/parents/payment-detials/_id" */))
const _28163622 = () => interopDefault(import('../pages/parents/stories/_id.vue' /* webpackChunkName: "pages/parents/stories/_id" */))
const _6c6696c6 = () => interopDefault(import('../pages/shop-users/orders/_item_id/index.vue' /* webpackChunkName: "pages/shop-users/orders/_item_id/index" */))
const _29d9e846 = () => interopDefault(import('../pages/shop/brands/_id/index.vue' /* webpackChunkName: "pages/shop/brands/_id/index" */))
const _9107baa6 = () => interopDefault(import('../pages/shop/categories/_id/index.vue' /* webpackChunkName: "pages/shop/categories/_id/index" */))
const _61291868 = () => interopDefault(import('../pages/shop/offers/_id/index.vue' /* webpackChunkName: "pages/shop/offers/_id/index" */))
const _20ebd296 = () => interopDefault(import('../pages/shop/products/_id/index.vue' /* webpackChunkName: "pages/shop/products/_id/index" */))
const _bbb1ff84 = () => interopDefault(import('../pages/admin/subscriptions/_id/edit.vue' /* webpackChunkName: "pages/admin/subscriptions/_id/edit" */))
const _034e836e = () => interopDefault(import('../pages/invite/_referralCode.vue' /* webpackChunkName: "pages/invite/_referralCode" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutUs",
    component: _255c4cb5,
    name: "aboutUs"
  }, {
    path: "/aboutUsPaytm",
    component: _4f9281c8,
    name: "aboutUsPaytm"
  }, {
    path: "/affiliate",
    component: _b4ee528c,
    name: "affiliate"
  }, {
    path: "/ccsupport",
    component: _88ae32ce,
    name: "ccsupport"
  }, {
    path: "/change-password",
    component: _20e51d42,
    name: "change-password"
  }, {
    path: "/claim-your-gift",
    component: _ce15723a,
    name: "claim-your-gift"
  }, {
    path: "/class-2-worksheets",
    component: _26e7843b,
    name: "class-2-worksheets"
  }, {
    path: "/contact-us",
    component: _1f2c6ab1,
    name: "contact-us"
  }, {
    path: "/dashboard",
    component: _6c142184,
    name: "dashboard"
  }, {
    path: "/email-template",
    component: _f10b705e,
    name: "email-template"
  }, {
    path: "/english-worksheet",
    component: _08ae75fb,
    name: "english-worksheet"
  }, {
    path: "/faq",
    component: _f9011780,
    name: "faq"
  }, {
    path: "/forgotpassword",
    component: _5c41ed84,
    name: "forgotpassword"
  }, {
    path: "/free-signup",
    component: _685e3143,
    name: "free-signup"
  }, {
    path: "/giftsubscription",
    component: _04ff2a13,
    name: "giftsubscription"
  }, {
    path: "/history",
    component: _1da685c1,
    name: "history"
  }, {
    path: "/login",
    component: _1c7d5a93,
    name: "login"
  }, {
    path: "/math-worksheet",
    component: _05f724a2,
    name: "math-worksheet"
  }, {
    path: "/myplan",
    component: _64f7301b,
    name: "myplan"
  }, {
    path: "/payNearBySignup",
    component: _30dbeb09,
    name: "payNearBySignup"
  }, {
    path: "/payNearBySignUpMessage",
    component: _5a847564,
    name: "payNearBySignUpMessage"
  }, {
    path: "/paytmlanding",
    component: _53700fc8,
    name: "paytmlanding"
  }, {
    path: "/paytmsubscribe",
    component: _7ece7ae2,
    name: "paytmsubscribe"
  }, {
    path: "/press-releases",
    component: _ede66b48,
    name: "press-releases"
  }, {
    path: "/privacypolicy",
    component: _094e2b64,
    name: "privacypolicy"
  }, {
    path: "/profileImageUpload",
    component: _ed7e31ce,
    name: "profileImageUpload"
  }, {
    path: "/referral",
    component: _00a0dd3a,
    name: "referral"
  }, {
    path: "/resetpassword",
    component: _545eacd8,
    name: "resetpassword"
  }, {
    path: "/returnpolicy",
    component: _2fc63ab0,
    name: "returnpolicy"
  }, {
    path: "/shop",
    component: _01fdccbf,
    name: "shop"
  }, {
    path: "/signon-new",
    component: _31a089f5,
    name: "signon-new"
  }, {
    path: "/signup-new",
    component: _13e20eb1,
    name: "signup-new"
  }, {
    path: "/signup-partner",
    component: _3b178959,
    name: "signup-partner"
  }, {
    path: "/signup-with-samples",
    component: _39eec541,
    name: "signup-with-samples"
  }, {
    path: "/ssr-test",
    component: _6f955133,
    name: "ssr-test"
  }, {
    path: "/staugustinesday",
    component: _d2eb16a2,
    name: "staugustinesday"
  }, {
    path: "/students",
    component: _01a8e43e,
    name: "students"
  }, {
    path: "/subscribe",
    component: _515696f4,
    name: "subscribe"
  }, {
    path: "/termsandcondition",
    component: _7c697b55,
    name: "termsandcondition"
  }, {
    path: "/test",
    component: _757bded8,
    name: "test"
  }, {
    path: "/token-login",
    component: _5e72ccfc,
    name: "token-login"
  }, {
    path: "/trial-ads",
    component: _bce0e8ba,
    name: "trial-ads"
  }, {
    path: "/try-sample-question",
    component: _c8645d7e,
    name: "try-sample-question"
  }, {
    path: "/under-maintenance",
    component: _4c3f0148,
    name: "under-maintenance"
  }, {
    path: "/varify-email",
    component: _2ee7018a,
    name: "varify-email"
  }, {
    path: "/admin/active-child",
    component: _0963de44,
    name: "admin-active-child"
  }, {
    path: "/admin/additional-points",
    component: _6a7aa576,
    name: "admin-additional-points"
  }, {
    path: "/admin/archive-points",
    component: _008dd252,
    name: "admin-archive-points"
  }, {
    path: "/admin/child-accuracy",
    component: _17ef3f90,
    name: "admin-child-accuracy"
  }, {
    path: "/admin/child-active-history",
    component: _188bc224,
    name: "admin-child-active-history"
  }, {
    path: "/admin/child-competition-details",
    component: _b4f0b8a0,
    name: "admin-child-competition-details"
  }, {
    path: "/admin/child-monthly-points",
    component: _fb6cf60c,
    name: "admin-child-monthly-points"
  }, {
    path: "/admin/child-points-history",
    component: _86fc86fe,
    name: "admin-child-points-history"
  }, {
    path: "/admin/competition-winner",
    component: _876d66da,
    name: "admin-competition-winner"
  }, {
    path: "/admin/coupons",
    component: _403f26ba,
    name: "admin-coupons"
  }, {
    path: "/admin/create-question",
    component: _4c465b01,
    name: "admin-create-question"
  }, {
    path: "/admin/create-question-set",
    component: _ca90c5d4,
    name: "admin-create-question-set"
  }, {
    path: "/admin/custom-ads",
    component: _13734346,
    name: "admin-custom-ads"
  }, {
    path: "/admin/daily-question-performance",
    component: _51504a83,
    name: "admin-daily-question-performance"
  }, {
    path: "/admin/edit-question",
    component: _1f072133,
    name: "admin-edit-question"
  }, {
    path: "/admin/encryption",
    component: _794c69ee,
    name: "admin-encryption"
  }, {
    path: "/admin/leaderboard",
    component: _5196dee7,
    name: "admin-leaderboard"
  }, {
    path: "/admin/leaderboard-referal",
    component: _1ea8db88,
    name: "admin-leaderboard-referal"
  }, {
    path: "/admin/lessons",
    component: _3867d268,
    name: "admin-lessons"
  }, {
    path: "/admin/question-performance",
    component: _60cddb12,
    name: "admin-question-performance"
  }, {
    path: "/admin/question-set-history",
    component: _94bc0d70,
    name: "admin-question-set-history"
  }, {
    path: "/admin/question-set-of-the-day",
    component: _0b8b2e46,
    name: "admin-question-set-of-the-day"
  }, {
    path: "/admin/questions",
    component: _5869fd37,
    name: "admin-questions"
  }, {
    path: "/admin/questionSets",
    component: _33c5eb5d,
    name: "admin-questionSets"
  }, {
    path: "/admin/shop-order",
    component: _5506cc00,
    name: "admin-shop-order"
  }, {
    path: "/admin/stories",
    component: _7efc7a80,
    name: "admin-stories"
  }, {
    path: "/admin/subscriptions",
    component: _984f603a,
    name: "admin-subscriptions"
  }, {
    path: "/admin/undermaintance",
    component: _1d1892d8,
    name: "admin-undermaintance"
  }, {
    path: "/admin/users",
    component: _5f165675,
    name: "admin-users"
  }, {
    path: "/admin/wild-card",
    component: _5ae4d0e0,
    name: "admin-wild-card"
  }, {
    path: "/advertisers/dashboard",
    component: _2b39845a,
    name: "advertisers-dashboard"
  }, {
    path: "/affiliate/sign-up",
    component: _61b42bda,
    name: "affiliate-sign-up"
  }, {
    path: "/affiliate/terms-and-conditions",
    component: _ded2a888,
    name: "affiliate-terms-and-conditions"
  }, {
    path: "/coupon-users/coupons",
    component: _0cf1a336,
    name: "coupon-users-coupons"
  }, {
    path: "/history/HistoryList",
    component: _157e56e1,
    name: "history-HistoryList"
  }, {
    path: "/parents/achievements",
    component: _5ee884b0,
    name: "parents-achievements"
  }, {
    path: "/parents/assessment",
    component: _f6a3db64,
    name: "parents-assessment"
  }, {
    path: "/parents/challenges",
    component: _57633e7f,
    name: "parents-challenges"
  }, {
    path: "/parents/chat-history",
    component: _572b756e,
    name: "parents-chat-history"
  }, {
    path: "/parents/chatTest",
    component: _31b7c496,
    name: "parents-chatTest"
  }, {
    path: "/parents/child-profile",
    component: _5a04ef9f,
    name: "parents-child-profile"
  }, {
    path: "/parents/claimgift",
    component: _6baf70e0,
    name: "parents-claimgift"
  }, {
    path: "/parents/create-child",
    component: _7b4841d2,
    name: "parents-create-child"
  }, {
    path: "/parents/daily-questions",
    component: _ed0d1986,
    name: "parents-daily-questions"
  }, {
    path: "/parents/digital-store",
    component: _ca815cda,
    name: "parents-digital-store"
  }, {
    path: "/parents/friend-request-approval",
    component: _092b5034,
    name: "parents-friend-request-approval"
  }, {
    path: "/parents/jump-request",
    component: _7c57a37c,
    name: "parents-jump-request"
  }, {
    path: "/parents/jump-test",
    component: _94bf4f16,
    name: "parents-jump-test"
  }, {
    path: "/parents/kids",
    component: _5516610e,
    name: "parents-kids"
  }, {
    path: "/parents/leaderboard",
    component: _a5740fbe,
    name: "parents-leaderboard"
  }, {
    path: "/parents/mykids",
    component: _cb43f7b6,
    name: "parents-mykids"
  }, {
    path: "/parents/parent-profile",
    component: _5b5ceb12,
    name: "parents-parent-profile"
  }, {
    path: "/parents/practice",
    component: _10459a8a,
    name: "parents-practice"
  }, {
    path: "/parents/pricing",
    component: _2ce80e26,
    name: "parents-pricing"
  }, {
    path: "/parents/social",
    component: _8719f188,
    name: "parents-social"
  }, {
    path: "/parents/stories",
    component: _a96ba58c,
    name: "parents-stories"
  }, {
    path: "/parents/subscription",
    component: _7b383bae,
    name: "parents-subscription"
  }, {
    path: "/parents/test",
    component: _7b2581c4,
    name: "parents-test"
  }, {
    path: "/parents/thank-you",
    component: _18769240,
    name: "parents-thank-you"
  }, {
    path: "/shop-users/orders",
    component: _32b7eba0,
    name: "shop-users-orders"
  }, {
    path: "/shop-users/products",
    component: _1d5c446c,
    name: "shop-users-products"
  }, {
    path: "/shop/brands",
    component: _0ca78c1c,
    name: "shop-brands"
  }, {
    path: "/shop/Brands",
    component: _44c6e262,
    name: "shop-Brands"
  }, {
    path: "/shop/cart",
    component: _c9b505f4,
    name: "shop-cart"
  }, {
    path: "/shop/categories",
    component: _315138c2,
    name: "shop-categories"
  }, {
    path: "/shop/Categories",
    component: _46d1d99f,
    name: "shop-Categories"
  }, {
    path: "/shop/checkout",
    component: _24c1fa2c,
    name: "shop-checkout"
  }, {
    path: "/shop/Deals",
    component: _5a27f2d4,
    name: "shop-Deals"
  }, {
    path: "/shop/offers",
    component: _ab877806,
    name: "shop-offers"
  }, {
    path: "/shop/Offers",
    component: _d55885cc,
    name: "shop-Offers"
  }, {
    path: "/shop/order-history",
    component: _e20e38f6,
    name: "shop-order-history"
  }, {
    path: "/shop/products",
    component: _784a70ca,
    name: "shop-products"
  }, {
    path: "/admin/additional-points/create",
    component: _0c8e5408,
    name: "admin-additional-points-create"
  }, {
    path: "/admin/advertisements/ad-specification",
    component: _3ffc75b2,
    name: "admin-advertisements-ad-specification"
  }, {
    path: "/admin/advertisements/advertisers",
    component: _7782a520,
    name: "admin-advertisements-advertisers"
  }, {
    path: "/admin/advertisements/formats",
    component: _1402f9a4,
    name: "admin-advertisements-formats"
  }, {
    path: "/admin/advertisements/units",
    component: _0ed59592,
    name: "admin-advertisements-units"
  }, {
    path: "/admin/coupons/affiliate-inquiries",
    component: _496d408d,
    name: "admin-coupons-affiliate-inquiries"
  }, {
    path: "/admin/coupons/coupon-usage",
    component: _8a3986fc,
    name: "admin-coupons-coupon-usage"
  }, {
    path: "/admin/digital-store/trades",
    component: _26399c98,
    name: "admin-digital-store-trades"
  }, {
    path: "/admin/shop/masters",
    component: _cef34c84,
    name: "admin-shop-masters"
  }, {
    path: "/admin/shop/offers",
    component: _f26b8086,
    name: "admin-shop-offers"
  }, {
    path: "/admin/shop/orders",
    component: _0d50b0ea,
    name: "admin-shop-orders"
  }, {
    path: "/admin/shop/products",
    component: _69487c8a,
    name: "admin-shop-products"
  }, {
    path: "/admin/subscriptions/create",
    component: _2234118a,
    name: "admin-subscriptions-create"
  }, {
    path: "/admin/subscriptions/track-subscriptions",
    component: _2b3bcc45,
    name: "admin-subscriptions-track-subscriptions"
  }, {
    path: "/parents/challenges/trending",
    component: _3e00dd08,
    name: "parents-challenges-trending"
  }, {
    path: "/parents/challenges/winners",
    component: _10efc578,
    name: "parents-challenges-winners"
  }, {
    path: "/parents/digital-store/avatars",
    component: _20690e8a,
    name: "parents-digital-store-avatars"
  }, {
    path: "/parents/digital-store/cars",
    component: _722b4dee,
    name: "parents-digital-store-cars"
  }, {
    path: "/parents/digital-store/collections",
    component: _404adfce,
    name: "parents-digital-store-collections"
  }, {
    path: "/parents/digital-store/icons",
    component: _790c17db,
    name: "parents-digital-store-icons"
  }, {
    path: "/parents/digital-store/inventory",
    component: _233be540,
    name: "parents-digital-store-inventory"
  }, {
    path: "/parents/digital-store/limited-deals",
    component: _62639e55,
    name: "parents-digital-store-limited-deals"
  }, {
    path: "/parents/digital-store/mystry-box",
    component: _a495b6be,
    name: "parents-digital-store-mystry-box"
  }, {
    path: "/parents/digital-store/new-arrivals",
    component: _232530f6,
    name: "parents-digital-store-new-arrivals"
  }, {
    path: "/parents/digital-store/orders",
    component: _7d385e18,
    name: "parents-digital-store-orders"
  }, {
    path: "/parents/digital-store/pets",
    component: _e0c598fa,
    name: "parents-digital-store-pets"
  }, {
    path: "/parents/digital-store/trade-offers",
    component: _06b44cb2,
    name: "parents-digital-store-trade-offers"
  }, {
    path: "/parents/practice/opponent",
    component: _6acab155,
    name: "parents-practice-opponent"
  }, {
    path: "/parents/practice/subjectSelector",
    component: _21a4933a,
    name: "parents-practice-subjectSelector"
  }, {
    path: "/parents/stories/storyList",
    component: _622ac1ca,
    name: "parents-stories-storyList"
  }, {
    path: "/shop/order-history/track",
    component: _2aca7104,
    name: "shop-order-history-track"
  }, {
    path: "/parents/digital-store/inventory/Profile",
    component: _3af5ed17,
    name: "parents-digital-store-inventory-Profile"
  }, {
    path: "/",
    component: _52d5b77c,
    name: "index"
  }, {
    path: "/parents/digital-store/trade-offers/history/:id",
    component: _179f4c62,
    name: "parents-digital-store-trade-offers-history-id"
  }, {
    path: "/admin/advertisements/advertisers/:id",
    component: _0d794bd8,
    name: "admin-advertisements-advertisers-id"
  }, {
    path: "/admin/advertisements/units/:id",
    component: _778d195f,
    name: "admin-advertisements-units-id"
  }, {
    path: "/admin/shop/offers/:id",
    component: _0ad33428,
    name: "admin-shop-offers-id"
  }, {
    path: "/admin/shop/orders/:item_id",
    component: _1e1624bc,
    name: "admin-shop-orders-item_id"
  }, {
    path: "/admin/shop/products/:id",
    component: _51c77b16,
    name: "admin-shop-products-id"
  }, {
    path: "/parents/challenges/winners/:id",
    component: _4644a0ac,
    name: "parents-challenges-winners-id"
  }, {
    path: "/parents/child-profile/certificate/:id",
    component: _f8fe25dc,
    name: "parents-child-profile-certificate-id"
  }, {
    path: "/parents/digital-store/collections/:id",
    component: _6f6812f8,
    name: "parents-digital-store-collections-id"
  }, {
    path: "/parents/digital-store/trade-offers/:id",
    component: _3c53be9d,
    name: "parents-digital-store-trade-offers-id"
  }, {
    path: "/admin/additional-points/:id",
    component: _2ff81561,
    name: "admin-additional-points-id"
  }, {
    path: "/admin/coupons/:id",
    component: _bd5b56b6,
    name: "admin-coupons-id"
  }, {
    path: "/admin/custom-ads/:id?",
    component: _b4bb57f6,
    name: "admin-custom-ads-id"
  }, {
    path: "/admin/lessons/:id",
    component: _1dd16260,
    name: "admin-lessons-id"
  }, {
    path: "/admin/stories/:id",
    component: _53d7552a,
    name: "admin-stories-id"
  }, {
    path: "/admin/users/:id",
    component: _1d452ee0,
    name: "admin-users-id"
  }, {
    path: "/admin/wild-card/:id",
    component: _3f2a7bcb,
    name: "admin-wild-card-id"
  }, {
    path: "/parents/challenges/:id",
    component: _e94656b2,
    name: "parents-challenges-id"
  }, {
    path: "/parents/edit-child/:id?",
    component: _3447d2d0,
    name: "parents-edit-child-id"
  }, {
    path: "/parents/payment-detials/:id?",
    component: _5a6dcf8c,
    name: "parents-payment-detials-id"
  }, {
    path: "/parents/stories/:id",
    component: _28163622,
    name: "parents-stories-id"
  }, {
    path: "/shop-users/orders/:item_id",
    component: _6c6696c6,
    name: "shop-users-orders-item_id"
  }, {
    path: "/shop/brands/:id",
    component: _29d9e846,
    name: "shop-brands-id"
  }, {
    path: "/shop/categories/:id",
    component: _9107baa6,
    name: "shop-categories-id"
  }, {
    path: "/shop/offers/:id",
    component: _61291868,
    name: "shop-offers-id"
  }, {
    path: "/shop/products/:id",
    component: _20ebd296,
    name: "shop-products-id"
  }, {
    path: "/admin/subscriptions/:id/edit",
    component: _bbb1ff84,
    name: "admin-subscriptions-id-edit"
  }, {
    path: "/invite/:referralCode?",
    component: _034e836e,
    name: "invite-referralCode"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
