const PREFIX = 'PracticeMultiPlayer:';
const PLAYER_PREFIX = PREFIX + 'Player:';
const GAME_PREFIX = PREFIX + 'Game:';
const INVITE_PREFIX = (userOne, userTwo) =>
  PREFIX + `Invite:${userOne}:${userTwo}`;
const IN_QUEUE_KEY = (subjectId, level) =>
  PREFIX + `${subjectId}:${level}:` + 'Queue';

const GAME_STAGES = {
  SETUP: 'setup',
  trackQuestion(n) {
    return `question-${n}-track`;
  }
};

const EVENTS = {
  JOIN: 'Join',
  GET_FRIENDS: 'GetFriends',
  GET_SESSION: 'GetSession',
  SEND_GAME_INVITE: 'SendGameInvite',
  RECEIVE_GAME_INVITE: 'ReceiveGameInvite',
  ACCEPT_GAME_INVITE: 'AcceptGameInvite',
  REJECT_GAME_INVITE: 'RejectGameInvite',
  GAME_INVITE_TIMEOUT: 'GameInviteTimedOut',
  FRIEND_IS_IN_GAME: 'FriendIsInGame',
  // QUESTION_TIMED_OUT: 'QuestionTimedOut',
  GAME_ENDED: 'GameEnded',
  NEW_QUESTION_RECEIVED: 'NewQuestionReceived',
  INCORRECT_ANSWER_RECEIVED: 'IncorrectAnswerReceived',
  CORRECT_ANSWER_RECEIVED: 'CorrectAnswerReceived',
  ANSWER_SUBMITTED: 'AnswerSubmitted',
  GAME_START_COUNTDOWN: 'GameStartCountdown',
  UPDATE_TIMER: 'UpdateTimer',
  FORCE_DISCONNECT: 'ForceDisconnect',
  LEFT_GAME: 'LeftGame',
  SET_OPPONENT: 'SetOpponent',
  INCOMING_REQUEST: 'IncomingRequest',
  CLEAR_INCOMING_REQUEST: 'ClearIncomingRequest',
  ACCEPT_INCOMING_REQUEST: 'AcceptIncomingRequest',
  REJECT_INCOMING_REQUEST: 'RejectIncomingRequest',
  SWITCH_CHILD: 'SwitchChild',
  PING: 'Ping',
};

module.exports = {
  PLAYER_PREFIX,
  GAME_PREFIX,
  GAME_STAGES,
  EVENTS,
  INVITE_PREFIX,
  IN_QUEUE_KEY
};
