import { render, staticRenderFns } from "./home.vue?vue&type=template&id=cbc0aa04&scoped=true"
import script from "./home.vue?vue&type=script&lang=js"
export * from "./home.vue?vue&type=script&lang=js"
import style0 from "./home.vue?vue&type=style&index=0&id=cbc0aa04&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbc0aa04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SignInSignUp: require('/codebuild/output/src687347117/src/client/components/SignInSignUp.vue').default,HomeNavbar: require('/codebuild/output/src687347117/src/client/components/home/HomeNavbar.vue').default,Sidebar: require('/codebuild/output/src687347117/src/client/components/Sidebar.vue').default,Snackbar: require('/codebuild/output/src687347117/src/client/components/Snackbar.vue').default,Footer: require('/codebuild/output/src687347117/src/client/components/Footer.vue').default})
